import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { getFirestore, collection, addDoc, query, where, getDocs } from 'firebase/firestore';
import { app } from '../../Firebase/firebaseConfig';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { X, CarFront, User, CreditCard, CheckCircle, Car, Palette, Fuel, Settings, Users, Calendar, MapPin, Clock, Info, ArrowRight, Phone } from 'lucide-react';
import { CircularProgress, Alert, Snackbar, Paper, Typography, Box,} from '@mui/material';
import { FaMoneyBillWave, FaPaypal, FaQrcode } from 'react-icons/fa';
import MonthCalendar from './MonthCalendar';

const db = getFirestore(app);

const BookingOverlay = ({ car, onClose }) => {
  const [step, setStep] = useState(1);
  const [selectedCar] = useState(car);
  const [customerData, setCustomerData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showComplete, setShowComplete] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('Cash');
  const [error, setError] = useState(null);

  const [bookingDetails, setBookingDetails] = useState({
    startDate: null,
    endDate: null,
    pickupLocation: '',
    dropoffLocation: '',
  });

  // Validation method to check step requirements
  const validateStep = () => {
    switch (step) {
      case 1:
        // Step 1 (Vehicle Details) always passes
        return true;
      case 2:
        // Check booking details
        return bookingDetails.startDate && 
               bookingDetails.endDate && 
               bookingDetails.pickupLocation && 
               bookingDetails.dropoffLocation;
      case 3:
        // Check customer data
        return customerData && 
               customerData.FirstName && 
               customerData.LastName && 
               customerData.EmailAddress && 
               customerData.ContactNumber;
      case 4:
        // Check payment method
        return paymentMethod;
      case 5:
        // Final confirmation step
        return true;
      default:
        return false;
    }
  };

  useEffect(() => {
    const fetchCustomerData = async () => {
      try {
        const auth = getAuth();
        onAuthStateChanged(auth, async (user) => {
          if (user) {
            const db = getFirestore();
            const customerQuery = query(
              collection(db, 'Customers'),
              where('EmailAddress', '==', user.email)
            );
            const querySnapshot = await getDocs(customerQuery);
            if (!querySnapshot.empty) {
              setCustomerData(querySnapshot.docs[0].data());
            } else {
              setError('No customer data found for this email.');
            }
          }
          setLoading(false);
        });
      } catch (error) {
        setError('Error fetching customer data');
        setLoading(false);
      }
    };
    fetchCustomerData();
  }, []);

  const handleNext = () => {
    if (validateStep()) {
      setStep((prevStep) => prevStep + 1);
      setError(null);
    } else {
      setError('Please fill in all required fields before proceeding.');
    }
  };

  const handlePrev = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const updateBookingDetails = (details) => {
    setBookingDetails(prev => ({
      ...prev,
      ...details
    }));
  };

  const handleConfirm = async () => {
    try {
      setLoading(true);
      const db = getFirestore();
      await addDoc(collection(db, 'Rentals'), {
        car: selectedCar,
        customer: customerData,
        bookingDate: new Date(),
        paymentMethod,
        ...bookingDetails,
        startDate: bookingDetails.startDate,
        endDate: bookingDetails.endDate,
        status: 'Pending'
      });

      setStep(6);
      setShowComplete(true);
      
      setTimeout(() => {
        onClose();
        window.location.reload();
      }, 5000);
    } catch (error) {
      setError('Error confirming booking. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={{
          hidden: { opacity: 0 },
          visible: { opacity: 1 },
          exit: { opacity: 0 }
        }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-75 backdrop-blur-sm overflow-y-auto py-8"
      >
        {showComplete ? (
          <CompleteOverlay onClose={onClose} />
        ) : (
          <motion.div
            variants={{
              hidden: { y: 20, opacity: 0 },
              visible: { y: 0, opacity: 1 },
              exit: { y: -20, opacity: 0 }
            }}
            className="bg-white p-8 w-[95%] md:w-[90%] lg:w-[80%] xl:w-[70%] rounded-lg flex flex-col relative shadow-xl min-h-[800px] h-[90%] md:h-[90%] lg:h-[80%] xl:h-[70%] overflow-y-auto"
          >
            <button
              onClick={onClose}
              className="absolute top-4 right-4 p-2 rounded hover:bg-gray-100 transition-colors z-10"
            >
              <X className="text-gray-500 hover:text-gray-700" size={24} />
            </button>

            <ProgressBar step={step} />

            <div className="flex-1 overflow-y-auto overflow-visible mt-4 px-4 sm:px-6 md:px-8">
              <AnimatePresence mode="wait">
                <motion.div
                  key={step}
                  initial={{ x: 10, opacity: 0 }}
                  animate={{ x: 0, opacity: 1 }}
                  exit={{ x: -10, opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className="w-full"
                >
                  {step === 1 && <Step1 car={selectedCar} />}
                  {step === 2 && (
                    <Step2 
                      car={selectedCar} 
                      onDetailsUpdate={updateBookingDetails} 
                      initialDetails={bookingDetails}
                    />
                  )}
                  {step === 3 && <Step3 customerData={customerData} loading={loading} />}
                  {step === 4 && (
                    <Step4 
                      paymentMethod={paymentMethod} 
                      setPaymentMethod={setPaymentMethod}
                    />
                  )}
                  {step === 5 && (
                    <Step5
                      car={selectedCar}
                      customerData={customerData}
                      paymentMethod={paymentMethod}
                      startDate={bookingDetails.startDate}
                      endDate={bookingDetails.endDate}
                      pickupLocation={bookingDetails.pickupLocation}
                      dropoffLocation={bookingDetails.dropoffLocation}
                      loading={loading}
                    />
                  )}
                </motion.div>
              </AnimatePresence>
            </div>

            <NavigationButtons
              step={step}
              onPrev={handlePrev}
              onNext={handleNext}
              onConfirm={handleConfirm}
              loading={loading}
            />
          </motion.div>
        )}

        <Snackbar
          open={!!error}
          autoHideDuration={6000}
          onClose={() => setError(null)}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Alert severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        </Snackbar>
      </motion.div>
    </AnimatePresence>
  );
};

const ProgressBar = ({ 
  step, 
  onStepClick, 
  completedSteps = [], 
  disabledSteps = [] 
}) => {
  const steps = [
    { icon: CarFront, label: 'Vehicle', description: 'Select your vehicle' },
    { icon: CarFront, label: 'Booking', description: 'Choose booking details' },
    { icon: User, label: 'Customer', description: 'Your information' },
    { icon: CreditCard, label: 'Payment', description: 'Select Payment' },
    { icon: CheckCircle, label: 'Confirm', description: 'Booking confirmation' }
  ];

  const stepVariants = {
    initial: { scale: 1, opacity: 0.7 },
    active: { 
      scale: 1.1, 
      opacity: 1,
      transition: { type: 'spring', stiffness: 300 }
    },
    completed: { 
      scale: 1, 
      opacity: 1,
      color: '#10b981' // Emerald color for completed steps
    }
  };

  const getStepStatus = (index) => {
    if (index + 1 === step) return 'active';
    if (completedSteps.includes(index + 1)) return 'completed';
    if (disabledSteps.includes(index + 1)) return 'disabled';
    return 'pending';
  };

  return (
    <div className="w-full px-2 sm:px-4 max-w-4xl mx-auto">
      <div className="flex justify-between items-center mb-2 sm:mb-4 overflow-x-auto">
        {steps.map((s, i) => {
          const status = getStepStatus(i);
          const isInteractive = onStepClick && 
            (status === 'completed' || status === 'pending');

          return (
            <div
              key={i}
              className={`
                flex flex-col items-center flex-grow basis-0 min-w-0 max-w-[120px] 
                ${isInteractive ? 'cursor-pointer hover:opacity-80' : ''}
              `}
              onClick={() => isInteractive && onStepClick(i + 1)}
            >
              <motion.div
                className={`
                  w-10 h-10 rounded-full flex items-center justify-center 
                  transition-colors duration-300
                  ${status === 'active' ? 'bg-orange-500 text-white' : 
                    status === 'completed' ? 'bg-emerald-500 text-white' : 
                    status === 'disabled' ? 'bg-gray-300 text-gray-500' : 
                    'bg-gray-200 text-gray-500'}
                `}
                variants={stepVariants}
                initial="initial"
                animate={status === 'active' ? 'active' : 
                  status === 'completed' ? 'completed' : 'initial'}
              >
                <s.icon 
                  className="w-6 h-6" 
                  strokeWidth={status === 'completed' ? 2.5 : 1.5} 
                />
              </motion.div>
              <span 
                className={`
                  text-xs sm:text-sm mt-1 text-center truncate
                  ${status === 'active' ? 'font-semibold text-orange-600' : 
                    status === 'completed' ? 'text-emerald-600' : 
                    status === 'disabled' ? 'text-gray-400' : 'text-gray-600'}
                `}
              >
                {s.label}
              </span>
            </div>
          );
        })}
      </div>
      
      {/* Progress Line */}
      <div className="w-full bg-gray-200 h-1.5 rounded relative">
        <motion.div
          className="absolute top-0 left-0 bg-orange-500 h-1.5 rounded"
          initial={{ width: 0 }}
          animate={{ 
            width: `${((step - 1) / (steps.length - 1)) * 100}%`,
            transition: { 
              duration: 0.5,
              type: 'tween'
            }
          }}
        />
      </div>

      {/* Step Description */}
      <AnimatePresence mode="wait">
        <motion.div 
          key={step}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -10 }}
          className="text-center text-sm text-gray-600 mt-2 h-6"
        >
          {steps[step - 1].description}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

const Step1 = ({ car }) => {
  const details = [
    { 
      label: 'Make', 
      value: car.make,
      icon: <Car className="w-5 h-5 text-orange-500" />
    },
    { 
      label: 'Model', 
      value: car.model,
      icon: <Calendar className="w-5 h-5 text-orange-500" />
    },
    { 
      label: 'Color', 
      value: car.color,
      icon: <Palette className="w-5 h-5 text-orange-500" />
    },
    { 
      label: 'Fuel Type', 
      value: car.fuelType,
      icon: <Fuel className="w-5 h-5 text-orange-500" />
    },
    { 
      label: 'Transmission', 
      value: car.transmissionType,
      icon: <Settings className="w-5 h-5 text-orange-500" />
    },
    { 
      label: 'Seats', 
      value: car.seatNumber,
      icon: <Users className="w-5 h-5 text-orange-500" />
    },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center"
    >
      <h2 className="text-xl md:text-2xl font-bold text-orange-500 mb-4 md:mb-6 flex items-center justify-center gap-2">
        <Car className="w-5 h-5 md:w-6 md:h-6" />
        Vehicle Details
      </h2>

      <div className="relative flex flex-col items-center w-full">
        <div className="w-full max-w-xl mx-auto px-4">
          <motion.div
            className="relative group"
            whileHover={{ scale: 1.02 }}
            transition={{ duration: 0.3 }}
          >
            <motion.img
              src={car.imageUrl || "/api/placeholder/800/400"}
              alt={`${car.make} ${car.model}`}
              className="w-full h-[200px] md:h-[250px] object-cover rounded-lg shadow-lg"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-black/30 to-transparent rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
          </motion.div>
        </div>
      </div>
        <div className="grid grid-cols-2 md:grid-cols-3 gap-3 md:gap-4 py-4 md:p-6">
          {details.map((detail, index) => (
            <motion.div
              key={detail.label}
              whileHover={{ 
                scale: 1.02,
                transition: { duration: 0.2 }
              }}
              className="p-2 md:p-4 bg-gray-50 rounded-lg hover:shadow-md transition-shadow duration-300 flex items-center"
            >
              <div className="flex items-center gap-2 md:gap-3 w-full">
                {detail.icon}
                <div className="text-left flex-1 overflow-hidden">
                  <p className="text-xs md:text-sm text-gray-500 truncate">{detail.label}</p>
                  <p className="text-sm md:text-base font-semibold mt-1 truncate">{detail.value}</p>
                </div>
              </div>
            </motion.div>
          ))}
        </div>
    </motion.div>
  );
};

const Step2 = ({ 
  car, 
  onDetailsUpdate, 
  initialDetails = {}, 
}) => {
  const [startDate, setStartDate] = useState(
    initialDetails.startDate ? initialDetails.startDate : ''
  );
  const [endDate, setEndDate] = useState(
    initialDetails.endDate ? initialDetails.endDate : ''
  );
  const [pickupLocation, setPickupLocation] = useState(initialDetails.pickupLocation || '');
  const [dropoffLocation, setDropoffLocation] = useState(initialDetails.dropoffLocation || '');
  const [selectedDates, setSelectedDates] = useState([]);
  const [bookedDates, setBookedDates] = useState([]);

  // Helper function to generate an array of dates between start and end
  const generateDateRange = (start, end) => {
    if (!start || !end) return [];
    const dates = [];
    const current = new Date(start);
    const last = new Date(end);

    while (current <= last) {
      dates.push(current.toISOString().split('T')[0]);
      current.setDate(current.getDate() + 1);
    }

    return dates;
  };

  // Trigger details update whenever values change
  useEffect(() => {
    if (onDetailsUpdate) {
      const generatedDates = startDate && endDate 
        ? generateDateRange(new Date(startDate), new Date(endDate))
        : [];

      onDetailsUpdate({
        startDate,
        endDate,
        selectedDates: generatedDates,
        pickupLocation,
        dropoffLocation,
      });
    }
  }, [startDate, endDate, pickupLocation, dropoffLocation, onDetailsUpdate]);
  
  // Handle start date change
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    
    // Reset end date if new start date is after current end date
    if (endDate && new Date(newStartDate) > new Date(endDate)) {
      setEndDate('');
    }

    setStartDate(newStartDate);
  };

  // Handle end date change
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    
    // Ensure end date is only set if start date exists and end date is after start date
    if (startDate && new Date(newEndDate) >= new Date(startDate)) {
      setEndDate(newEndDate);
    }
  };

  const handleCalendarDateSelect = (selectedDate) => {
    if (!startDate) {
      // If no start date is set, set the selected date as start date
      setStartDate(selectedDate);
    } else if (!endDate) {
      // If start date is set but no end date, set the selected date as end date
      // Ensure the selected date is after the start date
      if (new Date(selectedDate) >= new Date(startDate)) {
        setEndDate(selectedDate);
      } else {
        // If selected date is before start date, swap them
        setStartDate(selectedDate);
        setEndDate(startDate);
      }
    } else {
      // If both dates are set, reset and start over
      setStartDate(selectedDate);
      setEndDate('');
    }
  };

  // Fetch booked dates
  useEffect(() => {
    const fetchBookedDates = async () => {
      if (car) {
        try {
          // Assuming you're using Firestore
          const rentalsRef = collection(db, 'Rentals');
          const q = query(
            rentalsRef, 
            where('carId', '==', car.id), 
            where('status', '==', 'confirmed')
          );
  
          const querySnapshot = await getDocs(q);
          
          const bookedDateRanges = querySnapshot.docs.map(doc => {
            const data = doc.data();
            return {
              startDate: data.startDate,
              endDate: data.endDate
            };
          });
  
          // Generate a flat array of all booked dates
          const allBookedDates = bookedDateRanges.flatMap(range => 
            generateDateRange(new Date(range.startDate), new Date(range.endDate))
          );
  
          setBookedDates(allBookedDates);
        } catch (error) {
          console.error("Error fetching booked dates:", error);
        }
      }
    };
  
    fetchBookedDates();
  }, [car]);

  // Animation variants
  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: { opacity: 1, y: 0 }
  };

  // Custom date validation function
  const isDateDisabled = (dateString) => {
    if (!car) return true;
    
    const date = new Date(dateString);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Disable past dates
    if (date < today) return true;

    // Disable dates that are already booked
    return bookedDates.includes(dateString);
  };

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="flex flex-col items-center w-full max-w-4xl mx-auto px-4"
    >
      <motion.h2 
        variants={itemVariants}
        className="text-xl md:text-2xl font-bold text-orange-500 mb-4 md:mb-6 flex items-center gap-2 text-center w-full"
      >
        <Calendar className="w-5 h-5 md:w-6 md:h-6" />
        Select Dates and Location
      </motion.h2>

      <motion.div 
        variants={itemVariants}
        className="w-full grid grid-cols-1 lg:grid-cols-2 gap-4 md:gap-6"
      >
        {/* Dates Selection */}
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-xl shadow-md p-4 md:p-6"
        >
          <div className="flex items-center gap-2 mb-3 md:mb-4">
            <Clock className="w-4 h-4 md:w-5 md:h-5 text-orange-500" />
            <h3 className="text-base md:text-lg font-semibold text-gray-700">Rental Dates</h3>
          </div>

          <div className="space-y-3 md:space-y-4">
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">
                Pick-up Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={handleStartDateChange}
                min={new Date().toISOString().split('T')[0]}
                className="w-full px-2 py-1 md:px-3 md:py-2 text-xs md:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">
                Return Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={handleEndDateChange}
                min={startDate || new Date().toISOString().split('T')[0]}
                disabled={!startDate}
                className="w-full px-2 py-1 md:px-3 md:py-2 text-xs md:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent disabled:bg-gray-100 disabled:cursor-not-allowed"
              />
            </div>

            {/* Selected Dates Display */}
            {startDate && endDate && (
              <div className="mt-2">
                <h4 className="text-xs md:text-sm font-medium text-gray-700 mb-1">Selected Dates:</h4>
                <div className="flex flex-wrap gap-1">
                  {generateDateRange(new Date(startDate), new Date(endDate)).map(date => (
                    <span 
                      key={date} 
                      className={`text-xs px-2 py-1 rounded ${
                        bookedDates.includes(date) 
                          ? 'bg-red-100 text-red-700' 
                          : 'bg-orange-100 text-orange-700'
                      }`}
                    >
                      {date}
                    </span>
                  ))}
                </div>
              </div>
            )}
          </div>

            {/* New Month Calendar */}
        <MonthCalendar 
          startDate={startDate}
          endDate={endDate}
          bookedDates={bookedDates}
          onDateSelect={handleCalendarDateSelect}
        />

        </motion.div>

        {/* Locations Selection */}
        <motion.div
          variants={itemVariants}
          className="bg-white rounded-xl shadow-md p-4 md:p-6"
        >
          <div className="flex items-center gap-2 mb-3 md:mb-4">
            <MapPin className="w-4 h-4 md:w-5 md:h-5 text-orange-500" />
            <h3 className="text-base md:text-lg font-semibold text-gray-700">Pickup & Return Locations</h3>
          </div>

          <div className="space-y-3 md:space-y-4">
            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">
                Pick-up Location
              </label>
              <input
                type="text"
                value={pickupLocation}
                onChange={(e) => setPickupLocation(e.target.value)}
                placeholder="Enter pickup location"
                className="w-full px-2 py-1 md:px-3 md:py-2 text-xs md:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
              />
            </div>

            <div>
              <label className="block text-xs md:text-sm font-medium text-gray-700 mb-1 md:mb-2">
                Return Location
              </label>
              <input
                type="text"
                value={dropoffLocation}
                onChange={(e) => setDropoffLocation(e.target.value)}
                placeholder="Enter return location"
                className="w-full px-2 py-1 md:px-3 md:py-2 text-xs md:text-base border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-500 focus:border-transparent"
              />
            </div>
          </div>
        </motion.div>
      </motion.div>

      {/* Summary Section */}
      {startDate && endDate && pickupLocation && dropoffLocation && (
        <motion.div
          variants={itemVariants}
          className="mt-4 md:mt-6 w-full bg-orange-50 rounded-xl p-3 md:p-4"
        >
          <h4 className="font-medium text-orange-600 mb-2 text-sm md:text-base">Booking Summary</h4>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-2 md:gap-4">
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <Clock className="w-3 h-3 md:w-4 md:h-4 text-orange-500" />
              <p>
                {startDate} - {endDate} 
                <span className="ml-2 text-gray-500">
                  ({generateDateRange(new Date(startDate), new Date(endDate)).length} days)
                </span>
              </p>
            </div>
            <div className="flex items-center gap-2 text-xs md:text-sm">
              <MapPin className="w-3 h-3 md:w-4 md:h-4 text-orange-500" />
              <div>
                {pickupLocation} <ArrowRight className="w-3 h-3 md:w-4 md:h-4 inline mx-1" /> {dropoffLocation}
              </div>
            </div>
          </div>
        </motion.div>
      )}
    </motion.div>
  );
};

const Step3 = ({ customerData, loading }) => {
  // Show loading spinner while fetching data
  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <CircularProgress color="warning" />
      </div>
    );
  }

  // Check if customerData is available before accessing its properties
  if (!customerData) {
    return (
      <div className="flex justify-center items-center h-64 text-red-500">
        <p>Error: Customer data is not available</p>
      </div>
    );
  }

  // Prepare customer details with icons for better mobile readability
  const details = [
    { 
      label: 'Full Name', 
      value: `${customerData.FirstName} ${customerData.LastName}`,
      icon: <User className="w-5 h-5 text-orange-500 mr-3" />
    },
    { 
      label: 'Email', 
      value: customerData.EmailAddress,
      icon: <CreditCard className="w-5 h-5 text-orange-500 mr-3" />
    },
    { 
      label: 'Contact', 
      value: customerData.ContactNumber,
      icon: <Phone className="w-5 h-5 text-orange-500 mr-3" />
    }
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="flex flex-col items-center px-4"
    >
      <h2 className="text-xl md:text-2xl font-bold text-orange-500 mb-4 md:mb-6 text-center">
        Customer Details
      </h2>
      <div className="w-full max-w-md bg-white rounded-xl shadow-md overflow-hidden">
        {details.map((detail, index) => (
          <motion.div
            key={detail.label}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="p-3 md:p-4 border-b last:border-b-0 flex items-center"
          >
            <div className="flex items-center w-full">
              {detail.icon}
              <div className="flex-1">
                <p className="text-xs md:text-sm text-gray-500">{detail.label}</p>
                <p className="font-semibold mt-1 text-sm md:text-base truncate">
                  {detail.value}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

const Step4 = ({ paymentMethod, setPaymentMethod }) => {
  const paymentOptions = [
    { label: 'Cash', icon: <FaMoneyBillWave size={24} />, value: 'Cash' },
    { label: 'PayPal', icon: <FaPaypal size={24} />, value: 'PayPal' },
    { label: 'Gcash', icon: <FaQrcode size={24} />, value: 'Gcash' },
  ];

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-center"
    >
      <h2 className="text-2xl font-bold text-orange-500 mb-6">Payment Method</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 max-w-3xl mx-auto">
        {paymentOptions.map((option, index) => (
          <motion.button
            key={option.value}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1 }}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => setPaymentMethod(option.value)}
            className={`p-6 rounded-xl shadow-md transition-all ${
              paymentMethod === option.value
                ? 'bg-orange-50 border-2 border-orange-500'
                : 'bg-white border-2 border-transparent'
            }`}
          >
            <div className="flex flex-col items-center space-y-3">
              <div className={`${
                paymentMethod === option.value ? 'text-orange-500' : 'text-gray-600'
              }`}>
                {option.icon}
              </div>
              <span className="font-medium">{option.label}</span>
            </div>
          </motion.button>
        ))}
      </div>
    </motion.div>
  );
};

const Step5 = ({ 
  car = {}, 
  customerData = {}, 
  paymentMethod = '',
  startDate = null,
  endDate = null,
  pickupLocation = '',
  dropoffLocation = '', 
  loading = false
}) => {
  const sections = [
    {
      title: 'Vehicle Details',
      icon: <Car className="text-orange-500" />,
      items: [
        { label: 'Make', value: car?.make || '-' },
        { label: 'Model', value: car?.model || '-' },
        { label: 'Color', value: car?.color || '-' },
        { label: 'Fuel Type', value: car?.fuelType || '-' },
        { label: 'Transmission', value: car?.transmissionType || '-' },
        { label: 'Seat Capacity', value: car?.seatNumber || '-' }
      ]
    },
    {
      title: 'Rental Schedule',
      icon: <Calendar className="text-orange-500" />,
      items: [
        { 
          label: 'Pick-up Date', 
          value: startDate ? new Date(startDate).toLocaleDateString() : '-' 
        },
        { 
          label: 'Return Date', 
          value: endDate ? new Date(endDate).toLocaleDateString() : '-' 
        },
        { label: 'Pick-up Location', value: pickupLocation || '-' },
        { label: 'Return Location', value: dropoffLocation || '-' }
      ]
    },
    {
      title: 'Customer Information',
      icon: <User className="text-orange-500" />,
      items: [
        { 
          label: 'Full Name', 
          value: customerData ? 
            `${customerData.FirstName || ''} ${customerData.LastName || ''}`.trim() : 
            '-'
        },
        { label: 'Email', value: customerData?.EmailAddress || '-' },
        { label: 'Contact Number', value: customerData?.ContactNumber || '-' }
      ]
    },
    {
      title: 'Payment Details',
      icon: <CreditCard className="text-orange-500" />,
      items: [
        { label: 'Payment Method', value: paymentMethod || '-' }
      ]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { 
      opacity: 1, 
      y: 0,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 }
  };

  if (loading) {
    return (
      <Box 
        display="flex" 
        justifyContent="center" 
        alignItems="center" 
        minHeight="400px"
      >
        <CircularProgress color="warning" />
      </Box>
    );
  }

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      variants={containerVariants}
      className="max-w-4xl mx-auto space-y-6 px-4"
    >
      <motion.div variants={itemVariants} className="text-center space-y-2">
        <Typography variant="h4" className="text-orange-500 font-bold">
          Booking Summary
        </Typography>
        <Typography variant="body1" color="textSecondary">
          Please review your booking details
        </Typography>
      </motion.div>

      {sections.map((section, sectionIndex) => (
        <motion.div key={section.title} variants={itemVariants}>
          <Paper elevation={2} className="overflow-hidden">
            <Box className="p-4 bg-orange-50 flex items-center gap-2">
              {section.icon}
              <Typography variant="h6" className="text-orange-600 font-semibold">
                {section.title}
              </Typography>
            </Box>
            <Box className="p-4 space-y-3">
              {section.items.map((item) => (
                <motion.div
                  key={item.label}
                  variants={itemVariants}
                  className="flex justify-between items-center py-2"
                >
                  <Typography color="textSecondary">{item.label}</Typography>
                  <Typography className="font-medium">
                    {item.value}
                  </Typography>
                </motion.div>
              ))}
            </Box>
          </Paper>
        </motion.div>
      ))}

      <motion.div variants={itemVariants}>
        <Alert 
          severity="info"
          icon={<Info className="text-orange-500" />}
          className="bg-orange-50"
        >
          <Typography variant="subtitle2" className="font-medium">
            Booking Confirmation Notes:
          </Typography>
          <Box component="ul" className="list-disc list-inside mt-2 space-y-1">
            <li>Bring a valid ID for verification during pickup</li>
            <li>Full payment is required before vehicle release</li>
            <li>24-hour cancellation notice required</li>
            <li>Late returns may incur additional charges</li>
          </Box>
        </Alert>
      </motion.div>
    </motion.div>
  );
};

const CompleteOverlay = ({ onClose }) => (
  <motion.div
    initial={{ scale: 0.9, opacity: 0 }}
    animate={{ scale: 1, opacity: 1 }}
    exit={{ scale: 0.9, opacity: 0 }}
    className="bg-white p-8 rounded-xl shadow-xl w-full max-w-md text-center"
  >
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ delay: 0.2, type: "spring" }}
      className="w-16 h-16 bg-green-100 rounded-full mx-auto mb-6 flex items-center justify-center"
    >
      <CheckCircle className="text-green-500" size={32} />
    </motion.div>
    
    <h2 className="text-2xl font-bold text-orange-500 mb-4">Booking Complete!</h2>
    <p className="text-gray-600 mb-6">Thank you for booking with Magu Klowi Transport Services!</p>
    
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ delay: 0.4 }}
      className="text-sm text-gray-500 mb-6"
    >
      You will be redirected in a few seconds...
    </motion.div>
    
    <motion.button
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => {
        onClose();
        window.location.reload();
      }}
      className="bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition-colors"
    >
      Close
    </motion.button>
  </motion.div>
);

const NavigationButtons = ({ step, onPrev, onNext, onConfirm, loading }) => (
  <motion.div
    initial={{ opacity: 0, y: 20 }}
    animate={{ opacity: 1, y: 0 }}
    className="flex justify-between mt-6 px-4"
  >
    {step > 1 && (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onPrev}
        className="bg-gray-500 text-white px-6 py-2 rounded-lg hover:bg-gray-600 transition-colors"
        disabled={loading}
      >
        Back
      </motion.button>
    )}
    
    {step < 5 ? (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onNext}
        className="ml-auto bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition-colors"
        disabled={loading}
      >
        Next
      </motion.button>
    ) : (
      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onConfirm}
        className="ml-auto bg-orange-500 text-white px-6 py-2 rounded-lg hover:bg-orange-600 transition-colors flex items-center space-x-2"
        disabled={loading}
      >
        {loading ? (
          <>
            <CircularProgress size={20} color="inherit" />
            <span>Processing...</span>
          </>
        ) : (
          <span>Confirm Booking</span>
        )}
      </motion.button>
    )}
  </motion.div>
);

export default BookingOverlay;