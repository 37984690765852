import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot, updateDoc, doc, orderBy, getDoc } from 'firebase/firestore';
import { db } from '../../Firebase/firebaseConfig';
import { motion, AnimatePresence } from 'framer-motion';
import { Car, Clock, Search, AlertCircle, CheckCircle, MoreVertical, XCircle, ArrowUp, ArrowDown, X, Eye, Calendar, Users, DollarSign } from 'lucide-react';
import { Box, Button, Card, MenuItem, Menu } from '@mui/material';

// Status configurations
const STATUS_CONFIG = {
  active: { 
    icon: <CheckCircle />, 
    label: 'Active',
    color: 'text-green-600'
  },
  pending: { 
    icon: <AlertCircle />, 
    label: 'Pending',
    color: 'text-amber-600'
  },
  completed: { 
    icon: <Clock />, 
    label: 'Completed',
    color: 'text-blue-600'
  },
  cancelled: { 
    icon: <XCircle />, 
    label: 'Cancelled',
    color: 'text-red-600'
  }
};

const RentalsContent = () => {
  // State management
  const [statusAnchorEl, setStatusAnchorEl] = useState(null);
  const [selectedRental, setSelectedRental] = useState(null);
  const [isRentalDetailOpen, setIsRentalDetailOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('active');
  const [rentals, setRentals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [saveMessage, setSaveMessage] = useState('');
  const [sortConfig, setSortConfig] = useState({
    key: 'startDate', // default sort
    direction: 'desc'
  });
  const [stats, setStats] = useState({
    total: 0,
    active: 0,
    pending: 0,
    completed: 0,
    cancelled: 0
  });

  // Sorting logic for rentals
  const sortedRentals = React.useMemo(() => {
    let sortableRentals = [...rentals];
    
    if (sortConfig.key) {
      sortableRentals.sort((a, b) => {
        // Custom sorting logic based on different keys
        let valueA, valueB;
        
        switch (sortConfig.key) {
          case 'customer':
            valueA = `${a.customer.firstName} ${a.customer.lastName}`.toLowerCase();
            valueB = `${b.customer.firstName} ${b.customer.lastName}`.toLowerCase();
            break;
          case 'vehicle':
            valueA = `${a.vehicle.make} ${a.vehicle.model}`.toLowerCase();
            valueB = `${b.vehicle.make} ${b.vehicle.model}`.toLowerCase();
            break;
          case 'startDate':
            valueA = new Date(a.duration.startDate);
            valueB = new Date(b.duration.startDate);
            break;
          case 'amount':
            valueA = a.amount || 0;
            valueB = b.amount || 0;
            break;
          case 'status':
            valueA = a.status.toLowerCase();
            valueB = b.status.toLowerCase();
            break;
          case 'payment':
            valueA = a.payment.method.toLowerCase();
            valueB = b.payment.method.toLowerCase();
            break;
          default:
            return 0;
        }
        
        // Compare values
        if (valueA < valueB) return sortConfig.direction === 'asc' ? -1 : 1;
        if (valueA > valueB) return sortConfig.direction === 'asc' ? 1 : -1;
        return 0;
      });
    }
    
    // Apply existing filtering
    return sortableRentals.filter(rental => {
      const matchesStatus = rental.status.toLowerCase() === activeTab;
      const matchesSearch = searchQuery === '' ||
        rental.customer.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        rental.customer.lastName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        `${rental.vehicle.make} ${rental.vehicle.model}`.toLowerCase().includes(searchQuery.toLowerCase());
      return matchesStatus && matchesSearch;
    });
  }, [rentals, sortConfig, activeTab, searchQuery]);

  // Fetch rentals from Firebase
useEffect(() => {
  const fetchRentals = async () => {
    try {
      setLoading(true);
      const rentalsRef = collection(db, 'Rentals');
      const q = query(rentalsRef, orderBy('startDate', 'desc'));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const rentalsList = [];
        const statsCount = {
          total: 0,
          pending: 0,
          active: 0,
          completed: 0,
          cancelled: 0
        };

        snapshot.forEach((doc) => {
          const data = doc.data();
          const rental = {
            id: doc.id,
            customer: {
              firstName: data.customer?.FirstName || '',
              lastName: data.customer?.LastName || ''
            },
            vehicle: {
              make: data.car?.make || '',
              model: data.car?.model || ''
            },
            duration: {
              startDate: data.startDate || data.rentalStartDate,
              endDate: data.endDate || data.rentalEndDate
            },
            payment: {
              method: data.paymentMethod || 'Not Specified',
              amount: data.paymentAmount || null,
              status: data.paymentStatus || 'Not Processed'
            },
            paymentAmount: data.paymentAmount || null,
            paymentStatus: data.paymentStatus || 'Not Processed',
            status: data.status || 'Pending',
            daysLeft: calculateDaysLeft(data.endDate)
          };
          
          rentalsList.push(rental);
          
          // Update stats
          statsCount.total++;
          statsCount[rental.status.toLowerCase()] = 
            (statsCount[rental.status.toLowerCase()] || 0) + 1;
        });

        setRentals(rentalsList);
        setStats(statsCount);
        setLoading(false);
      }, (error) => {
        console.error("Error fetching rentals:", error);
        setError(error);
        setLoading(false);
      });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error setting up rental listener:", error);
      setError(error);
      setLoading(false);
    }
  };

  fetchRentals();
}, []);

  // Date and Days Calculation Helpers
  const formatDate = (date) => {
    if (!date) return '';
    
    // Check if date is a Firestore Timestamp
    const dateToFormat = date?.seconds 
      ? new Date(date.seconds * 1000) 
      : new Date(date);
    
    // Fallback to current date if invalid
    if (isNaN(dateToFormat)) return '';
  
    return dateToFormat.toLocaleDateString('en-US', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    });
  };

  const calculateDaysLeft = (endDate) => {
    if (!endDate) return 0;
    
    // Handle both Firestore Timestamp and Date objects
    const end = endDate?.seconds 
      ? new Date(endDate.seconds * 1000) 
      : new Date(endDate);
    
    const now = new Date();
    const diffTime = end - now;
    return Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
  };

  // Sorting handler
  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'desc' 
        ? 'asc' 
        : 'desc'
    }));
  };

  // Sorting icon component
  const SortIcon = ({ sortKey }) => {
    if (sortConfig.key !== sortKey) {
      return <ArrowDown className="h-3 w-3 text-gray-300 ml-1" />;
    }
    
    return sortConfig.direction === 'asc' 
      ? <ArrowUp className="h-3 w-3 text-blue-600 ml-1" />
      : <ArrowDown className="h-3 w-3 text-blue-600 ml-1" />;
  };

  // Event Handlers
  const handleStatusMenuOpen = (event, rental) => {
    event.stopPropagation();
    setStatusAnchorEl(event.currentTarget);
    setSelectedRental(rental);
  };
  
  const handleStatusMenuClose = () => {
    setStatusAnchorEl(null);
    setSelectedRental(null);
  };

  const handleStatusChange = async (newStatus) => {
    if (!selectedRental) return;

    try {
      const rentalRef = doc(db, 'Rentals', selectedRental.id);
      await updateDoc(rentalRef, {
        status: newStatus,
        updatedAt: new Date()
      });
      handleStatusMenuClose();
    } catch (error) {
      console.error('Error updating rental status:', error);
      setError(error);
    }
  };

  const handleView = async (rental) => {
    try {
      const rentalRef = doc(db, 'Rentals', rental.id);
      const rentalSnapshot = await getDoc(rentalRef);
      
      if (rentalSnapshot.exists()) {
        const fullRentalData = rentalSnapshot.data();
        setSelectedRental({
          ...rental,
          fullDetails: {
            bookingDate: fullRentalData.bookingDate,
            car: fullRentalData.car || {},
            customer: fullRentalData.customer || {},
            dropoffLocation: fullRentalData.dropoffLocation,
            endDate: fullRentalData.endDate,
            paymentMethod: fullRentalData.paymentMethod,
            pickupLocation: fullRentalData.pickupLocation,
            startDate: fullRentalData.startDate,
            status: fullRentalData.status
          }
        });
        setIsRentalDetailOpen(true);
      }
    } catch (error) {
      console.error('Error fetching rental details:', error);
      setError(error);
    }
  };

  const handleCloseRentalDetail = () => {
    setSelectedRental(null);
    setIsRentalDetailOpen(false);
  };

  const updateRentalDetail = (field, value) => {
    // Update local state
    setSelectedRental(prev => ({
      ...prev,
      fullDetails: {
        ...prev.fullDetails,
        [field]: value
      }
    }));
  };
  
  const handleSavePaymentDetails = async () => {
    try {
      // Ensure we have a valid rental ID
      if (!selectedRental || !selectedRental.id) {
        return;
      }
  
      // Reference to the specific rental document
      const rentalDocRef = doc(db, 'Rentals', selectedRental.id);
  
      // Only update paymentAmount and paymentStatus
      await updateDoc(rentalDocRef, {
        'paymentAmount': selectedRental.fullDetails.paymentAmount || null,
        'paymentStatus': selectedRental.fullDetails.paymentStatus || 'Pending'
      });
  
      // Show success message
      setSaveMessage('Payment details successfully saved!');
      setTimeout(() => {
        setSaveMessage('');
      }, 3000);
    } catch (error) {
      setSaveMessage('Failed to save payment details.');
    }
  };

  // Stats Configuration
  const currentStats = [
    {
      title: 'Total Rentals',
      value: stats.total.toString(),
      icon: Car,
      color: 'text-blue-600',
      bgColor: 'bg-blue-50'
    },
    {
      title: 'Active Rentals',
      value: stats.active.toString(),
      icon: CheckCircle,
      color: 'text-green-600',
      bgColor: 'bg-green-50'
    },
    {
      title: 'Pending',
      value: stats.pending.toString(),
      icon: AlertCircle,
      color: 'text-amber-600',
      bgColor: 'bg-amber-50'
    },
    {
      title: 'Completed',
      value: stats.completed.toString(),
      icon: Clock,
      color: 'text-blue-600',
      bgColor: 'bg-blue-50'
    },
    {
      title: 'Cancelled',
      value: stats.cancelled.toString(),
      icon: XCircle,
      color: 'text-red-600',
      bgColor: 'bg-red-50'
    }
  ];

  return (
    <Box sx={{ p: 3 }}>
      {/* Stats Section */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-4 mb-6">
        {currentStats.map((stat, index) => (
          <div 
            key={index} 
            className={`p-4 rounded-lg flex items-center ${stat.bgColor}`}
          >
            <div className={`mr-4 ${stat.color}`}>
              <stat.icon size={24} />
            </div>
            <div>
              <p className="text-sm text-gray-500">{stat.title}</p>
              <p className="text-2xl font-bold">{stat.value}</p>
            </div>
          </div>
        ))}
      </div>

      {/* Search and Actions Section */}
      <div className="flex flex-col md:flex-row gap-4 mb-6">
        <div className="flex-grow relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Search className="h-5 w-5 text-gray-400" />
          </div>
          <input
            type="text"
            placeholder="Search rentals..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="block w-full pl-10 pr-3 py-2.5 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 bg-white text-sm placeholder-gray-400 focus:outline-none transition-colors duration-200"
          />
        </div>
      </div>

      {/* Tabs Navigation */}
      <div className="border-b border-gray-200 mb-6">
        <div className="flex space-x-6">
          {Object.entries(STATUS_CONFIG).map(([key, { icon, label }]) => (
            <button
              key={key}
              onClick={() => setActiveTab(key)}
              className={`flex items-center space-x-2 pb-4 px-2 focus:outline-none ${
                activeTab === key
                  ? 'border-b-2 border-blue-600 text-blue-600'
                  : 'text-gray-500 hover:text-gray-700'
              }`}
            >
              {React.cloneElement(icon, {
                className: `h-4 w-4 ${activeTab === key ? 'text-blue-600' : 'text-gray-400'}`
              })}
              <span className="font-medium text-sm">{label}</span>
            </button>
          ))}
        </div>
      </div>

      {/* Main Content */}
      {loading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 border border-red-200 rounded-lg p-4 mb-6">
          <div className="flex items-center text-red-600 mb-2">
            <AlertCircle className="h-5 w-5 mr-2" />
            <span className="font-medium">Error loading rentals</span>
          </div>
          <p className="text-red-500 text-sm">{error.message}</p>
        </div>
      ) : (
        <AnimatePresence mode="wait">
          <motion.div
            key={activeTab}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
          >
            <Card className="overflow-hidden">
              {sortedRentals.length > 0 ? (
                <div className="overflow-x-auto">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                      {[
                          { key: 'customer', label: 'Customer' },
                          { key: 'vehicle', label: 'Vehicle' },
                          { key: 'startDate', label: 'Duration' },
                          { key: 'paymentAmount', label: 'Amount' },
                          { key: 'paymentStatus', label: 'Payment Status' },
                          { key: 'payment', label: 'Payment' },
                          { key: 'status', label: 'Status' },
                          { label: 'Actions', sortable: false }
                        ].map(({ key, label, sortable = true }) => (
                          <th 
                            key={label} 
                            className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                          >
                            <button 
                              onClick={() => sortable && handleSort(key)}
                              className={`flex items-center ${sortable ? 'cursor-pointer hover:text-gray-700' : ''}`}
                            >
                              {label}
                              {sortable && <SortIcon sortKey={key} />}
                            </button>
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {sortedRentals.map((rental) => (
                        <tr key={rental.id}>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm font-medium text-gray-900">
                              {rental.customer.firstName} {rental.customer.lastName}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {rental.vehicle.make} {rental.vehicle.model}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {formatDate(rental.duration.startDate)} - {formatDate(rental.duration.endDate)}
                            </div>
                            <div className={`text-xs mt-1 ${
                              rental.daysLeft <= 2 ? 'text-red-600' : 'text-gray-500'
                            }`}>
                              {rental.daysLeft} days left
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {rental.payment.amount || 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {rental.payment.status || 'N/A'}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <div className="text-sm text-gray-900">
                              {rental.payment.method}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap">
                            <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              {rental.status}
                            </span>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm font-medium">
  <div className="flex space-x-2">
    <button 
      onClick={() => handleView(rental)}
      className="text-blue-600 hover:text-blue-900"
      title="View Rental Details"
    >
      <Eye className="h-5 w-5" />
    </button>
    <button 
      onClick={(e) => handleStatusMenuOpen(e, rental)}
      className="text-gray-600 hover:text-gray-900"
    >
      <MoreVertical className="h-5 w-5" />
    </button>
  </div>
</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  className="flex flex-col items-center justify-center py-12"
                >
                  <div className="bg-gray-50 rounded-full p-3 mb-4">
                    <Car className="h-6 w-6 text-gray-400" />
                  </div>
                  <h3 className="text-lg font-medium text-gray-900 mb-1">No rentals found</h3>
                  <p className="text-sm text-gray-500 mb-4">
                    {searchQuery
                      ? 'No rentals match your search criteria'
                      : `No ${activeTab} rentals available`}
                  </p>
                  {searchQuery && (
                    <Button
                      variant="outlined"
                      onClick={() => setSearchQuery('')}
                      className="text-sm"
                    >
                      Clear search
                    </Button>
                  )}
                </motion.div>
              )}
            </Card>
          </motion.div>
        </AnimatePresence>
      )}

      {/* Rental Detail Overlay */}
      {isRentalDetailOpen && selectedRental && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center p-4 overflow-y-auto">
          <motion.div 
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="bg-white rounded-xl shadow-2xl max-w-2xl w-full relative"
          >
            <div className="p-6">
              <div className="flex justify-between items-center mb-6">
                <h2 className="text-xl font-bold text-gray-800">Detailed Rental Information</h2>
                <button 
                  onClick={handleCloseRentalDetail}
                  className="text-gray-500 hover:text-gray-700"
                >
                  <X size={24} />
                </button>
              </div>

              <div className="grid md:grid-cols-2 gap-6">
                {/* Vehicle Details */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4 flex items-center">
                    <Car className="h-5 w-5 mr-3 text-blue-600" />
                    Vehicle Details
                  </h3>
                  <div className="space-y-2">
                    <div>
                      <p className="text-sm text-gray-500">Make & Model</p>
                      <p className="font-medium">{selectedRental.fullDetails.car.make} {selectedRental.fullDetails.car.model}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Color</p>
                      <p className="font-medium">{selectedRental.fullDetails.car.color}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Fuel Type</p>
                      <p className="font-medium">{selectedRental.fullDetails.car.fuelType}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Year</p>
                      <p className="font-medium">{selectedRental.fullDetails.car.year}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Transmission</p>
                      <p className="font-medium">{selectedRental.fullDetails.car.transmissionType}</p>
                    </div>
                  </div>
                </div>

                {/* Customer Details */}
                <div className="bg-gray-50 p-4 rounded-lg">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4 flex items-center">
                    <Users className="h-5 w-5 mr-3 text-green-600" />
                    Customer Information
                  </h3>
                  <div className="space-y-2">
                    <div>
                      <p className="text-sm text-gray-500">Full Name</p>
                      <p className="font-medium">
                        {selectedRental.fullDetails.customer.FirstName} {selectedRental.fullDetails.customer.LastName}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Contact Number</p>
                      <p className="font-medium">{selectedRental.fullDetails.customer.ContactNumber}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Email</p>
                      <p className="font-medium">{selectedRental.fullDetails.customer.EmailAddress}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Address</p>
                      <p className="font-medium">
                        {selectedRental.fullDetails.customer.Address?.Street}, 
                        {selectedRental.fullDetails.customer.Address?.City}, 
                        {selectedRental.fullDetails.customer.Address?.Province} 
                        {selectedRental.fullDetails.customer.Address?.ZIP}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Rental Details */}
                <div className="bg-gray-50 p-4 rounded-lg md:col-span-2">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4 flex items-center">
                    <Calendar className="h-5 w-5 mr-3 text-purple-600" />
                    Rental Information
                  </h3>
                  <div className="grid md:grid-cols-3 gap-4">
                    <div>
                      <p className="text-sm text-gray-500">Booking Date</p>
                      <p className="font-medium">{formatDate(selectedRental.fullDetails.bookingDate)}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Rental Period</p>
                      <p className="font-medium">
                        {formatDate(selectedRental.fullDetails.startDate)} - {formatDate(selectedRental.fullDetails.endDate)}
                      </p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Status</p>
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium 
                        ${selectedRental.fullDetails.status === 'active' ? 'bg-green-100 text-green-800' : 
                          selectedRental.fullDetails.status === 'pending' ? 'bg-amber-100 text-amber-800' : 
                          selectedRental.fullDetails.status === 'completed' ? 'bg-blue-100 text-blue-800' : 
                          'bg-red-100 text-red-800'}`}
                      >
                        {selectedRental.fullDetails.status}
                      </span>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Pickup Location</p>
                      <p className="font-medium">{selectedRental.fullDetails.pickupLocation}</p>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Dropoff Location</p>
                      <p className="font-medium">{selectedRental.fullDetails.dropoffLocation}</p>
                    </div>
                  </div>
                </div>

                {/* Payment Details */}
                <div className="bg-gray-50 p-4 rounded-lg md:col-span-2">
                  <h3 className="text-lg font-semibold text-gray-700 mb-4 flex items-center">
                    <DollarSign className="h-5 w-5 mr-3 text-emerald-600" />
                    Payment Information
                  </h3>
                  <div className="grid md:grid-cols-3 gap-4 items-center">
                    <div>
                      <p className="text-sm text-gray-500">Payment Amount</p>
                      <div className="flex items-center">
                        <input 
                          type="number" 
                          value={selectedRental.fullDetails.paymentAmount || ''}
                          onChange={(e) => {
                            const newAmount = e.target.value !== '' ? parseFloat(e.target.value) : null;
                            updateRentalDetail('paymentAmount', newAmount);
                          }}
                          className="w-full px-2 py-1 border rounded text-sm"
                          min="0"
                          step="0.01"
                          placeholder="Enter payment amount"
                        />
                      </div>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500">Payment Status</p>
                      <select 
                        value={selectedRental.fullDetails.paymentStatus || 'Pending'}
                        onChange={(e) => {
                          updateRentalDetail('paymentStatus', e.target.value);
                        }}
                        className="w-full px-2 py-1 border rounded text-sm"
                      >
                        <option value="Pending">Pending</option>
                        <option value="Paid">Paid</option>
                        <option value="Overdue">Overdue</option>
                        <option value="Refunded">Refunded</option>
                      </select>
                    </div>
                    {/* Save Button */}
                    <div className="mt-4 flex justify-end">
                      <button 
                        onClick={handleSavePaymentDetails}
                        className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition-colors"
                      >
                        Save Payment Details
                      </button>
                    </div>
                  </div>
                  
                  {/* Success Message */}
                  {saveMessage && (
                    <div className="mt-4 text-center">
                      <div className={`
                        px-4 py-2 rounded 
                        ${saveMessage.includes('successfully') 
                          ? 'bg-green-100 text-green-800' 
                          : 'bg-red-100 text-red-800'}
                      `}>
                        {saveMessage}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      )}

      {/* Status Change Menu */}
      <Menu
        anchorEl={statusAnchorEl}
        open={Boolean(statusAnchorEl)}
        onClose={handleStatusMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {Object.entries(STATUS_CONFIG).map(([status, { label, icon: Icon }]) => {
          // Define color mapping for each status
          const statusColors = {
            'Pending': 'text-amber-600 hover:bg-amber-50',
            'Active': 'text-green-600 hover:bg-green-50',
            'Completed': 'text-blue-600 hover:bg-blue-50',
            'Cancelled': 'text-red-600 hover:bg-red-50'
          };

          return (
            <MenuItem
              key={status}
              onClick={() => handleStatusChange(status)}
              className={`${statusColors[status] || 'text-gray-600 hover:bg-gray-50'}`}
            >
              <div className="flex items-center space-x-2">
                {React.cloneElement(Icon, { size: 16 })}
                <span>{label}</span>
              </div>
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
};

export default RentalsContent;