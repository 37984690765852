import React, { useState, useMemo, useEffect } from 'react';
import { motion } from 'framer-motion';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../Firebase/firebaseConfig';

const MonthCalendar = ({ 
  startDate, 
  endDate, 
  onDateSelect 
}) => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [bookedDates, setBookedDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Fetch booked dates
  useEffect(() => {
    const fetchBookedDates = async () => {
      try {
        setIsLoading(true);
        
        const rentalsRef = collection(db, 'Rentals');
        const q = query(rentalsRef, where('selectedDates', '!=', null));

        const querySnapshot = await getDocs(q);
        
        // Collect all booked dates
        const allBookedDates = querySnapshot.docs
          .flatMap(doc => doc.data().selectedDates || [])
          .map(dateStr => new Date(dateStr).toISOString().split('T')[0]);

        // Remove duplicates
        const uniqueBookedDates = [...new Set(allBookedDates)];
        
        setBookedDates(uniqueBookedDates);
      } catch (error) {
        console.error("Error fetching booked dates:", error);
        setBookedDates([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchBookedDates();
  }, [selectedMonth]);

  // Generate calendar grid
  const calendarGrid = useMemo(() => {
    const year = selectedMonth.getFullYear();
    const month = selectedMonth.getMonth();
    
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const days = [];
    const startingDayOfWeek = firstDay.getDay();
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Fill in empty cells before the first day
    for (let i = 0; i < startingDayOfWeek; i++) {
      days.push(null);
    }

    // Fill in actual dates
    for (let day = 1; day <= lastDay.getDate(); day++) {
      const currentDate = new Date(year, month, day);
      const dateString = currentDate.toISOString().split('T')[0];
      
      days.push({
        date: day,
        dateString,
        isPastDate: currentDate < today,
        isBooked: bookedDates.includes(dateString),
        isSelected: startDate && endDate && 
          currentDate >= new Date(startDate) && 
          currentDate <= new Date(endDate)
      });
    }

    return days;
  }, [selectedMonth, startDate, endDate, bookedDates]);

  // Check if a date range contains or intersects with any booked dates
  const isDateRangeAvailable = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    
    // Determine the full range to check
    const earliestDate = startDate < endDate ? startDate : endDate;
    const latestDate = startDate < endDate ? endDate : startDate;
    
    // Check for any booked dates within or adjacent to the range
    for (let d = new Date(earliestDate); d <= latestDate; d.setDate(d.getDate() + 1)) {
      const dateString = d.toISOString().split('T')[0];
      if (bookedDates.includes(dateString)) {
        return false;
      }
    }
    return true;
  };

  const handleDateClick = (dayObj) => {
    if (!dayObj || dayObj.isBooked || dayObj.isPastDate) return;
  
    // Create the date and add one day
    const currentDate = new Date(
      selectedMonth.getFullYear(), 
      selectedMonth.getMonth(), 
      dayObj.date + 1  // Key change: adding 1 to the day
    );
  
    const selectedDateString = currentDate.toISOString().split('T')[0];
    // If an end date is not yet selected
    if (!endDate) {
      // Check if the new date is after or before the start date
      const startDateObj = new Date(startDate);
  
      // Determine earlier and later dates
      const earlierDate = currentDate < startDateObj ? currentDate : startDateObj;
      const laterDate = currentDate < startDateObj ? startDateObj : currentDate;
  
      // Check if the entire range is available
      if (isDateRangeAvailable(earlierDate, laterDate)) {
        onDateSelect(selectedDateString);
      } else {
        alert("Some dates in this range are already booked. Please select a different date range.");
      }
    } else {
      // If both start and end dates exist, reset to the new date
      onDateSelect(selectedDateString);
    }
  };

  // Month navigation
  const changeMonth = (direction) => {
    const newMonth = new Date(selectedMonth);
    newMonth.setMonth(newMonth.getMonth() + direction);
    setSelectedMonth(newMonth);
  };

  // Render loading state
  if (isLoading) {
    return (
      <div className="bg-white rounded-xl shadow-md p-4 md:p-6 flex justify-center items-center">
        <span>Loading availability...</span>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-md p-4 md:p-6">
      <div className="flex items-center justify-between mb-4">
        <button 
          onClick={() => changeMonth(-1)}
          className="p-2 rounded-full hover:bg-gray-100 transition"
        >
          ←
        </button>
        <h3 className="text-lg font-semibold">
          {selectedMonth.toLocaleString('default', { month: 'long', year: 'numeric' })}
        </h3>
        <button 
          onClick={() => changeMonth(1)}
          className="p-2 rounded-full hover:bg-gray-100 transition"
        >
          →
        </button>
      </div>

      <div className="grid grid-cols-7 gap-1 text-center">
        {['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'].map(day => (
          <div key={day} className="text-xs font-medium text-gray-500">{day}</div>
        ))}
        {calendarGrid.map((dayObj, index) => (
          <motion.div
            key={index}
            className={`
              aspect-square flex items-center justify-center 
              text-sm rounded-lg transition
              ${dayObj === null ? 'bg-transparent' : ''}
              ${dayObj && dayObj.isPastDate 
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed' 
                : ''}
              ${dayObj && dayObj.isBooked 
                ? 'bg-red-100 text-red-500 cursor-not-allowed' 
                : ''}
              ${dayObj && dayObj.isSelected 
                ? 'bg-orange-200 text-orange-700' 
                : ''}
              ${dayObj && !dayObj.isBooked && !dayObj.isPastDate && !dayObj.isSelected 
                ? 'hover:bg-orange-100 hover:text-orange-700 cursor-pointer' 
                : ''}
            `}
            onClick={() => handleDateClick(dayObj)}
            whileTap={{ scale: dayObj && !dayObj.isBooked && !dayObj.isPastDate ? 0.95 : 1 }}
          >
            {dayObj ? dayObj.date : ''}
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default MonthCalendar;